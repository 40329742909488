import React, { Fragment, Component } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutOB';
import SubHeader from '../components/SubHeaderOB';
// import * as ROUTES from '../src/constants/routes';
import Players from '../components/PlayerView';


class AllPlayerPageOb extends React.Component {

  render() {
    return (
        <Fragment>
      <SubHeader />
      <title>選手一覧 國學院大學久我山高等学校 バスケットボール部</title>
<Players />
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <AllPlayerPageOb />
  </Layout>
);
